import { useState, useEffect, useMemo } from 'react';

export type Currency = {
  code: string;
  symbol: string;
  countryCode: string;
};

const supportedCurrencies: Currency[] = [
  {
    code: 'ARS',
    symbol: '$',
    countryCode: 'AR'
  },
  {
    code: 'BRL',
    symbol: 'R$',
    countryCode: 'BR'
  },
  {
    code: 'COP',
    symbol: '$',
    countryCode: 'CO'
  },
  {
    code: 'CLP',
    symbol: '$',
    countryCode: 'CL'
  },
  {
    code: 'MXN',
    symbol: '$',
    countryCode: 'MX'
  },
  {
    code: 'UYU',
    symbol: '$',
    countryCode: 'UY'
  },
  {
    code: "PAB",
    symbol: "B/.",
    countryCode: "PA"
  },
  {
    code: "BOB",
    symbol: "Bs.",
    countryCode: "BO"
  },
  {
    code: "CRC",
    symbol: "₡",
    countryCode: "CR"
  },
  {
    code: "PYG",
    symbol: "₲",
    countryCode: "PY"
  },
  {
    code: "PEN",
    symbol: "S/.",
    countryCode: "PE"
  },
  {
    code: 'USD',
    symbol: 'USD',
    countryCode: 'XX'
  }
];

const useCurrency = (country: string | undefined): Currency | undefined => {
  const [currency, setCurrency] = useState<Currency>();

  const initialCurrency = useMemo(() => {
    const currency = supportedCurrencies.find(
      cur => country && cur.countryCode === country.toUpperCase()
    );

    // Default to USD for international markets
    return currency
      ? currency
      : supportedCurrencies.find(cur => cur.code === 'USD');
  }, [country]);

  useEffect(() => {
    if (country) setCurrency(initialCurrency);
  }, [country]);

  return currency;
};

export default useCurrency;
