import create from 'zustand';

type UseDisclaimerType = {
  disclaimer: boolean;
  setDisclaimer: (disclaimer: boolean) => void;
};

const useDisclaimer = create<UseDisclaimerType>(
  set => ({
    disclaimer: false,
    setDisclaimer: newDisclaimer => set(() => ({ disclaimer: newDisclaimer }))
  })
);

export default useDisclaimer;