import {
  FAQ_INTENSIVE_URL,
  FAQ_ON_DEMAND_URL,
  FAQ_URL,
  PRIVACY_POLICY_INTENSIVE_URL,
  PRIVACY_POLICY_ON_DEMAND_URL,
  PRIVACY_POLICY_URL,
  REGULATION_INTENSIVE_URL,
  REGULATION_URL,
  TERMS_DISCLAIMER_INTENSIVE_URL,
  TERMS_DISCLAIMER_ON_DEMAND_URL,
  TERMS_DISCLAIMER_URL,
} from 'model/constants';
import { EBusinessUnit, ECountries } from 'model/enums';


const getDisclaimerIntensive = (country) => ({
  faqUrl: FAQ_INTENSIVE_URL[country],
  termsDisclaimerUrl: TERMS_DISCLAIMER_INTENSIVE_URL[country],
  privacyPolicyUrl: PRIVACY_POLICY_INTENSIVE_URL[country],
  regulationUrl: REGULATION_INTENSIVE_URL[country]
})

const getDisclaimerOnDemand = (country) => ({
  faqUrl: FAQ_ON_DEMAND_URL,
  termsDisclaimerUrl: TERMS_DISCLAIMER_ON_DEMAND_URL[country],
  privacyPolicyUrl: PRIVACY_POLICY_ON_DEMAND_URL[country],
  regulationUrl: TERMS_DISCLAIMER_ON_DEMAND_URL[country]
}
)
const getDisclaimerDefault = (country) => ({
  faqUrl: FAQ_URL[country],
  termsDisclaimerUrl: TERMS_DISCLAIMER_URL[country],
  privacyPolicyUrl: PRIVACY_POLICY_URL[country],
  regulationUrl: REGULATION_URL[country]
})

const useDisclaimerUrl = (country = ECountries.INT, businessUnit = '') => {
  switch (businessUnit) {
    case EBusinessUnit.course:
      return getDisclaimerIntensive(country) ; 
    case EBusinessUnit.on_demand:
      return getDisclaimerOnDemand(country) ; 
    default:
      return getDisclaimerDefault(country) ; 
  }
};

export default useDisclaimerUrl;
