import styled, { css } from 'styled-components';
import { CardTitle } from 'common/styles/Cards.styled';

interface StyleProps {
  selected?: boolean;
  disabled?: boolean;
}

export const TopIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
`;

export const GroupItem = styled.div`
  position: relative;
  width: 100%;

  ${({ selected }: StyleProps) =>
    selected &&
    css`
      ${TopIcon} {
        color: var(--dh-black);
        top: -2px;
        right: -2px;
      }
    `}
`;

export const Title = styled(CardTitle)`
  text-transform: uppercase;
  font-weight: bold;
  
  ${({disabled}: StyleProps) =>
    disabled &&
    css`
      color: var(--dh-gray8);
    `
  }
`;

export const Info = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--dh-mediumgrey);
  line-height: 20px;

  ${({disabled}: StyleProps) =>
    disabled &&
    css`
      color: var(--dh-gray2);
    `
  }
`;

export const TextCard = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: var(--dh-gray2);
  font-weight: 500;
  margin: 0 0 6px 0;
`;
