import React, { FunctionComponent } from 'react';
import { IPaymentPlan, IProduct } from 'model/interfaces';
import { useTranslation } from 'next-i18next';
import useProduct from 'hooks/useProduct';
import { EBusinessUnit } from 'model/enums';

interface Props {
  plan: IPaymentPlan;
  product: IProduct;
}

const PaymentPlanTitle: FunctionComponent<Props> = ({ plan, product }) => {
  const { t } = useTranslation('main');
  const { installmentCount } = plan;
  const { isSubscription, isBusinessUnit } = useProduct(product);
  const isOnDemand = isBusinessUnit(EBusinessUnit['on_demand']);
  let installmentText = '';

  switch (true) {
    case isSubscription:
      installmentText = t('common.subscription');
      break;
    case isOnDemand:
      installmentText = t('payment.monthlyPayments', { installmentCount });
      break;
    default:
      installmentText = t('payment.monthlyPayment');
      break;
  }

  return (
    <>
      {plan.installmentCount > 1
        ? installmentText
        : t('payment.payment')}
    </>
  );
};

export default PaymentPlanTitle;
