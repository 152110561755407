import React, { FunctionComponent } from 'react';
import {
  GroupItem,
  Title,
  Info,
  TopIcon,
  TextCard
} from './ProductGroupItem.styled';
import { IProductGroup } from 'model/interfaces';
import { Edit, AlertTriangle } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import ProductGroupStartEndDate from './ProductGroupStartEndDate';
import ProductGroupStartingHour from './ProductGroupStartingHour';

interface Props {
  group: IProductGroup;
  soldOut?: boolean;
  lastPlaces?: boolean;
  selected?: boolean;
  maxLines?: number;
  editable?: boolean;
}

const ProductGroupItem: FunctionComponent<Props> = ({
  group,
  soldOut,
  lastPlaces,
  selected,
  maxLines,
  editable,
  ...props
}) => {
  const { t } = useTranslation('main');

  return (
    <GroupItem selected={selected} disabled={soldOut} {...props}>
      <TextCard>
        {soldOut ? t('group.quotasExhausted') : t('group.beginCourse')}
      </TextCard>
      <Title disabled={soldOut}>
        <ProductGroupStartEndDate group={group} />
      </Title>

      {!soldOut && (
        <Info>
          {group.slotFrom && (
            <ProductGroupStartingHour slotFrom={group.slotFrom} />
          )}
          {(selected && editable && (
            <TopIcon>
              <Edit />
            </TopIcon>
          )) ||
            (lastPlaces && (
              <TopIcon>
                <Tooltip title={t('group.lastQuotas')} placement="top" arrow>
                  <AlertTriangle />
                </Tooltip>
              </TopIcon>
            ))}
        </Info>
      )}
    </GroupItem>
  );
};

export default ProductGroupItem;
