import styled, { css, createGlobalStyle } from 'styled-components';
import { LoadingButton } from '@material-ui/lab';
import { Menu, X } from 'react-feather';

interface StyleProps {
  asideToggled?: boolean;
  highlight?: boolean;
  alertNew?: boolean;
}

export const GlobalStyle = createGlobalStyle`
  body {
    ${({ asideToggled }: StyleProps) =>
      asideToggled &&
      css`
        overflow: hidden;
      `}
  }
`;

export const Container = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--dh-gray2);
  display: flex;
  z-index: 100;
  overflow-y: auto;

  /* Mobile */
  @media screen and (max-width: 1199px) {
    padding: var(--asideMobilePadding);
    left: 0;
    box-shadow: 0px 4px 8px rgba(5, 0, 56, 0.08);
    height: ${({ asideToggled }: StyleProps) =>
      asideToggled ? '100%' : 'var(--asideFixedMobileHeigth)'};
    transition: height 0.4s;
    overflow: hidden;
    max-heigth: min-content;
  }

  /* Desktop */
  @media screen and (min-width: 1200px) {
    bottom: 0;
    padding: var(--asideDesktopPadding);
    width: var(--asideRelativeWidth);
    min-width: var(--asideFixedWidth);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Desktop */
  @media screen and (min-width: 1200px) {
    max-width: var(--asideFixedWidth);
  }
`;

export const AsideHeader = styled.header`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;

  > div {
    flex: 1;
  }
`;

export const HeaderLabel = styled.div`
  display: none;
`;

export const HeaderName = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  color: var(--dh-white1);

  /* Desktop */
  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }

  /* Mobile & Tablet */
  @media screen and (max-width: 1199px) {
    ${({ asideToggled }: StyleProps) =>
      !asideToggled &&
      css`
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      `}
  }
`;

export const AsideFooter = styled.footer`
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--dh-white1);
    margin: 0px;
    line-height: 0px;
    padding-bottom: 10px;
  }
`;

export const AsideFooterLink = styled.div`
  margin: 16px 0 0;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 42px;
  a {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition-property: color;
    transition: var(--hoverTransition);

    span {
      display: none;
    }

    &:hover {
      color: var(--dh-darkgrey);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-thickness: 2px;
    }
  }

  /* Desktop & Tablet */
  @media screen and (min-width: 768px) {
    height: 24px;
    a {
      span {
        display: flex;
        color: var(--dh-white2);
        font-size: 16px;
        text-decoration-line: underline;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;

export const AlertBadge = styled.span`
  display: none;
  position: absolute;
  top: 6px;
  right: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--colorActive);
  border: 2px solid var(--dh-white);
`;

export const MenuIcon = styled(Menu)``;
export const XIcon = styled(X)``;

export const ToggleButton = styled.a`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--dh-white2);
  margin-left: 16px;
  padding: 7px;
  transition: transform 0.2s;
  position: relative;

  ${({ asideToggled }: StyleProps) =>
    (asideToggled &&
      css`
        transform: rotate(180deg);

        ${MenuIcon} {
          display: none;
        }
      `) ||
    css`
      ${XIcon} {
        display: none;
      }
    `}

  ${({ alertNew }: StyleProps) =>
    alertNew &&
    css`
      ${AlertBadge} {
        display: block;
      }
    `}

  /* Desktop */
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export const AsideBody = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  /* Mobile */
  @media screen and (max-width: 1199px) {
    margin-top: 8px;
    overflow-y: ${({ asideToggled }: StyleProps) =>
      asideToggled ? 'auto' : 'hidden'};
  }
`;

export const SubmitButton = styled(LoadingButton)`
  span {
    font-weight: bold;
  }

  /* Mobile */
  &.MuiButtonBase-root {
    background-color:  var(--dh-white3);
    color: var(--dh-black1);
    &:hover {
      background-color: var(--dh-white4);
      color: var(--dh-black1);
    }
    &.Mui-disabled {
      color: var(--dh-gray5);
      background-color:  var(--dh-gray7);
    }
  }
  @media screen and (max-width: 1199px) {
    &.MuiButtonBase-root {
      display: none;
    }
  }
`;
