import { FunctionComponent } from 'react';
import {
  CheckboxStyles,
  Text,
  CheckboxStylesOne
} from 'components/TermsDisclaimer/TermsDisclaimer.styled';
import { Checkbox } from '@material-ui/core';
import useDisclaimer from 'hooks/useDisclaimer';
import { useTranslation } from 'next-i18next';
import useBusinessUnit from 'hooks/useBusinessUnit';
import useDisclaimerUrl from 'hooks/useDisclaimerUrl';

interface Props {
  mobile?: boolean;
  country: string | undefined;
}

const TermsDisclaimer: FunctionComponent<Props> = ({ country,...props }) => {
  const [disclaimer, setDisclaimer] = useDisclaimer(state => [
    state.disclaimer,
    state.setDisclaimer
  ]);
  const businessUnit = useBusinessUnit(state => state.businessUnit);

  const onChange = value => {
    const checked = value?.target?.checked;
    setDisclaimer(checked);
  };

  const { termsDisclaimerUrl, privacyPolicyUrl, regulationUrl } = useDisclaimerUrl(country as any, businessUnit);

  const { t } = useTranslation('main');

  return (
    <>
      <Text {...props}>
        <Checkbox
          onChange={onChange}
          color="primary"
          checked={disclaimer}
          sx={props.mobile ? CheckboxStylesOne : CheckboxStyles}
        />
        {t('review.accept')}{' '}
        <a
          href={termsDisclaimerUrl}
          target="_blank"
          className="active"
        >
          {t('review.terms')}
        </a>,{' '}
        <a
          href={privacyPolicyUrl}
          target="_blank"
          className="active"
        >
          {t('review.privacyPolicy')}
        </a>{' '}
        {t('review.and')}{' '}
        <a
          href={regulationUrl}
          target="_blank"
          className="active"
        >
          {t('review.regulation')}
        </a>{' '}
        {t('review.digitalHouse')}
      </Text>
    </>
  );
};

export default TermsDisclaimer;
