import styled, { css, createGlobalStyle } from 'styled-components';
import ProductGroupItem from 'components/ProductGroup/ProductGroupItem';

interface StyleProps {
  asideToggled?: boolean;
  highlight?: boolean;
}

export const GlobalStyle = createGlobalStyle`
  body {
    ${({ asideToggled }: StyleProps) =>
      asideToggled &&
      css`
        overflow: hidden;
      `}
  }
`;

export const DetailBlock = styled.div`
  margin: 40px 0;

  /* Big screen */
  @media screen and (min-height: 670px) {
    margin: 32px 0;
  }
`;

export const BlockHead = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: var(--dh-white);
`;

export const BlockBody = styled.div`
  margin-top: 8px;
  font-size: 16px;
  color: var(--dh-white2);
  line-height: 1.5;
  font-weight: 500;
`;

export const BlockRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ highlight }: StyleProps) =>
    highlight &&
    css`
      font-weight: 500;
      margin-top: 8px;
    `}
`;

export const BlockRowLeft = styled.div`
  color: var(--dh-white2);
`;
export const BlockRowRight = styled.div`
  color: var(--dh-white2);
  text-align: right;
  font-size: 17px;
`;

export const AsideProductGroupItem = styled(ProductGroupItem)`
  div,
  div > div {
    font-size: 16px;
    color: var(--dh-white2);
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
  }
`;

export const Remarked = styled.span`
  text-decoration-line: line-through;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  color: var(--dh-gray12);
  padding-right: 8px;

  /* Small screens */
  @media screen and (max-width: 359px) {
    display: none;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  color: var(--dh-white1);

  ${({ highlight }: StyleProps) =>
    highlight &&
    css`
      font-size: 16px;
    `}
`;

export const Price = styled.span`
  font-size: 20px;
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: transparent;
  background-color: var(--dh-gray9);
  margin-bottom: 20px;
`;

export const TextPrice = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PriceParagraph = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: var(--dh-gray8);
  margin: 0px;
`;
