import styled, { css } from 'styled-components';

import colors from 'styles/colors.module.scss';

interface StyleProps {
  mobile?: boolean;
}

export const CheckboxStyles = {
  color: colors.dhgrey,
  borderRadius: '0px',
  width: '23px',
  height: '23px',
  marginTop: '-2px',
  marginRight: '5px',
  '&.Mui-checked': {
    color: colors.dhgrey
  }
};
export const CheckboxStylesOne = {
  color: colors.dhgrey2,
  borderRadius: '0px',
  width: '23px',
  height: '23px',
  marginTop: '-2px',
  marginRight: '5px',
  '&.Mui-checked': {
    color: colors.dhgrey2
  }
};

export const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--dh-white4);
  margin-bottom: 24px;
  > a {
    color: var(--dh-white);
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      color: var(--dh-white3);
    }
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }

  ${({ mobile }: StyleProps) =>
    (mobile &&
      css`
        color: var(--dh-gray2);
        > a {
          color: var(--dh-gray2);
        }
        /* Desktop */
        @media screen and (min-width: 1200px) {
          display: none;
        }
      `) ||
    css`
      /* Mobile & Tablet */
      @media screen and (max-width: 1199px) {
        display: none;
      }
    `}
`;

export const ContainerQuestion = styled.div`
  padding-bottom: 13px;
  /* Mobile & Tablet */
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;
