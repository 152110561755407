import styled from 'styled-components';

export const DescriptionParagraph = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: var(--dh-gray2);
    margin-bottom: 20px;
    margin-top: 20px;
`;

