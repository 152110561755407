import create from 'zustand';

type UseBusinessUnitType = {
  businessUnit: string;
  setBusinessUnit: (businessUnit: string) => void;
};

/** @todo: Incorporar useBusinessUnit dentro de useProduct */
const useBusinessUnit = create<UseBusinessUnitType>(
  set => ({
    businessUnit: '',
    setBusinessUnit: newBusinessUnit => set(() => ({ businessUnit: newBusinessUnit }))
  })
);

export default useBusinessUnit;