import { FAQ_INTENSIVE_URL, FAQ_URL } from 'model/constants';
import { EBusinessUnit } from 'model/enums';
import { useState, useEffect } from 'react';
import useDisclaimerUrl from './useDisclaimerUrl';

const useFaqLink = (country: string | undefined, businessUnit = '') => {
  const { faqUrl } = useDisclaimerUrl(country as any, businessUnit);
  
  return [
    faqUrl
  ];
};

export default useFaqLink;
