import React, { FunctionComponent } from 'react';
import useCountryCode from 'hooks/useCountryCode';
import useDateLocale from 'hooks/useDateLocale';
import { format, parseISO } from 'date-fns';

interface Props {
  slotFrom: string;
}

const ProductGroupStartingHour: FunctionComponent<Props> = ({ slotFrom }) => {
  /**
   * @review me: this logic about hours may be extratect into utilities module
   * slotFrom only contains hours minutes and seconds (eg. 14:00:00)
   */
  const [country] = useCountryCode();
  const dateLocale = useDateLocale(country);

  const dummyShortDate = format(new Date(), 'yyyy-MM-dd');
  const GTM0 = '+00:00'; // slot from cames at GMT 0
  const fullSlotFrom = `${dummyShortDate}T${slotFrom}${GTM0}`;

  /**
   * return hours using Browser GMT offset
   */
  return (
    <>
      {format(parseISO(fullSlotFrom), 'h:mm a', {
        locale: dateLocale
      })}
    </>
  );
};

export default ProductGroupStartingHour;
