import styled from 'styled-components';

export const PriceAndCurrency = styled.span`
  color: var(--dh-white);
`;
export const EnrollmentPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: var(--dh-white1);

  /* Desktop */
  @media screen and (min-width: 1200px) {
    margin: 8px 0px;
    font-size: 18px;
  }
`;

export const ProductInfoText = styled.p`
  margin: 16px 0;
  line-height: 1.3;
  color: var(--dh-white2);
`;

export const EnrollmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: var(--dh-white);
  height: 78vh;

  /* Desktop */
  @media screen and (min-width: 1200px) {
    height: 0px;
  }
`;
export const QuestionContainer = styled.div`
  h4 {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    color: var(--dh-white1);
    margin: 0 0 16px;
  }
  #questionsFooterLink {
    display: flex;
    color: var(--dh-white2);
    font-size: 16px;
    text-decoration-line: underline;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const H2 = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--dh-white1);
  width: 100%;

  @media screen and (min-width: 1200px) {
    margin: 24px 0px 0px 0px;
  }
`;
export const EnrollmentBooking = styled.div`
  display: flex;
  flex-direction: column;
`;
