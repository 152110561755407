import {
  IOffer,
  IPaymentMethod,
  IPaymentPlan,
  IInitialPrice,
  IEnrollmentPrice
} from 'model/interfaces';

export const getInitialPrice = (
  offer: IOffer | undefined | null,
  currency: string | undefined
): IInitialPrice | undefined => {
  return offer && offer.plans && currency
    ? offer.plans
        .filter(({ currency: cur }) => cur === currency)
        .reduce(
          (
            min,
            {
              enrollmentAmount,
              installmentCount,
              installmentAmount,
              currency: cur
            }
          ) => {
            if (min.amount === -1 || min.amount > installmentAmount) {
              return {
                enrollmentAmount: enrollmentAmount,
                amount: installmentAmount,
                installments: installmentCount,
                currency: cur
              };
            }
            return min;
          },
          { installments: 1, amount: -1, currency: '' }
        )
    : undefined;
};

export const getEnrollmentPrice = (
  offer: IOffer | undefined | null,
  currency: string | undefined
): IEnrollmentPrice | undefined => {
  return offer && offer.plans && currency
    ? offer.plans
        .filter(({ currency: cur }) => cur === currency)
        .reduce(
          (
            min,
            { enrollmentAmount, remarkedEnrollmentAmount, currency: cur, enrollmentDiscount }
          ) => {
            if (min.amount > enrollmentAmount) {
              return {
                amount: enrollmentAmount,
                remarkedAmount: remarkedEnrollmentAmount,
                discount: enrollmentDiscount,
                currency: cur
              };
            }
            return min;
          },
          {
            discount: Infinity,
            amount: Infinity,
            remarkedAmount: Infinity,
            currency: ''
          }
        )
    : undefined;
};

export const getOfferPlans = (
  offer: IOffer | undefined | null,
  currency: string | undefined
): IPaymentPlan[] | undefined => {
  return offer && offer.plans && currency
    ? offer.plans.filter(({ currency: cur }) => cur === currency)
    : undefined;
};

export const getOfferPaymentMethods = (
  offer: IOffer | undefined | null,
  currency: string | undefined
): IPaymentMethod[] | undefined => {
  return offer && offer.plans && currency
    ? offer.plans
        .filter(({ currency: cur }) => cur === currency)
        .reduce<IPaymentMethod[]>(
          (methods, plan) => [...plan.methods, ...methods],
          []
        )
        .filter(
          (method, index, self) =>
            self.findIndex(m => m.id === method.id) === index
        )
    : undefined;
};
