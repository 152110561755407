import React, { FunctionComponent } from 'react';
import { useTranslation } from 'next-i18next';
import { IProductGroup } from 'model/interfaces';
import { addMinutes, format, parseISO } from 'date-fns';
import useCountryCode from 'hooks/useCountryCode';
import useDateLocale from 'hooks/useDateLocale';

interface Props {
  group: IProductGroup;
  longStrings?: boolean;
}

const ProductGroupStartEndDate: FunctionComponent<Props> = ({ group, longStrings }) => {
  const { t } = useTranslation('main');
  const [country] = useCountryCode();
  const dateLocale = useDateLocale(country);

  return <>
    {
      format(
        addMinutes(
          parseISO(group.startsAt as string),
          parseISO(group.startsAt as string).getTimezoneOffset()
        ),
        longStrings ? t('common.dateLongMonth') : t('common.dateShortMonth'),
        { locale: dateLocale }
      )
    }
  </>;
};

export default ProductGroupStartEndDate;
