import { useEffect } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { IProduct } from 'model/interfaces';
import { EBusinessUnit } from 'model/enums';

type TUseProductType = {
  product?: IProduct;
  setProduct: (newProduct: IProduct) => void;
};

const useProductStore = create<TUseProductType>(
  devtools(
    (set) => ({
      product: {} as IProduct,
      setProduct: (newProduct) => {
        set({product: newProduct});
      },
    })
  )
);

const useProduct = (data?: IProduct) => {
  const { product, setProduct } = useProductStore();
  const isBusinessUnit = (key: keyof typeof EBusinessUnit) => key === product?.businessUnit;
  const isSubscription = product?.isSubscription;

  useEffect(() => {
    if (!data) return;
    
    setProduct(data)
  }, [data])

  return { product, isBusinessUnit, isSubscription };
}

export default useProduct;
