import create from 'zustand';

type UseIsInstantPaymentType = {
  isInstantPayment: boolean;
  setIsInstantPayment: (isInstantPay: boolean) => void;
};

// TODO: this will be extracted from api graph
const useIsInstantPayment = create<UseIsInstantPaymentType>(
  set => ({
    isInstantPayment: false,
    setIsInstantPayment: newIsInstantPayment => set(() => (
      { isInstantPayment: newIsInstantPayment }
    ))
  })
);

export default useIsInstantPayment;