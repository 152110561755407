import styled, { css } from 'styled-components';

interface StyleProps {
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  mobileHorizontalScroll?: boolean;
  skeleton?: boolean;
  selectedClickable?: boolean;
  minHeight?: number;
}

export const CardGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ skeleton }: StyleProps) =>
    skeleton &&
    css`
      display: block;
      > span {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }

        &::after {
          content: '';
          width: 1px;
        }

        ${({ mobileHorizontalScroll }: StyleProps) =>
          mobileHorizontalScroll &&
          css`
            /* Mobile */
            @media screen and (max-width: 767px) {
              overflow-x: auto;
              margin: -16px -24px;
              padding: 16px 24px;
              grid-auto-flow: column;
              grid-auto-columns: 90%;
              grid-template-columns: repeat(auto-fill, 90%);
            }
          `}
      }
    `}

  ${({ mobileHorizontalScroll }: StyleProps) =>
    mobileHorizontalScroll &&
    css`
      /* Mobile */
      @media screen and (max-width: 767px) {
        overflow-x: auto;
        margin: -16px -24px;
        padding: 16px 24px;
        grid-auto-flow: column;
        grid-auto-columns: 90%;
        grid-template-columns: repeat(auto-fill, 90%);

        &::after {
          content: '';
          width: 1px;
        }
      }
    `}
`;

export const CardTitle = styled.div`
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--dh-black2);

  ${({disabled}: StyleProps) =>
    disabled &&
    css`
      color: var(--dh-black2);
    `
  }
`;

export const Card = styled.button`
  display: flex;
  flex-direction: column;
  transition-property: transform, box-shadow, color, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  border: 0;
  text-align: left;
  color: var(--dh-black);
  background-color: var(--dh-white2);
  padding: 24px;
  border-radius: 8px;
  scroll-snap-align: center;
  min-height: ${({ minHeight }: StyleProps) =>
    minHeight ? `${minHeight}px` : 'auto'};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
    outline-color: var(--dh-black);
    outline-width: 2px;
  }

  ${({ disabled, selected, selectedClickable }: StyleProps) =>
    (!selected || selectedClickable) &&
    !disabled &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: 2px 2px 16px rgba(5, 0, 56, 0.15);
        transform: translateY(-2px);
      }
      &:active {
        transform: translateY(0px);
      }
    `}

  ${({ selected }: StyleProps) =>
    selected &&
    css`
      border: 3px solid var(--dh-black);
      padding: 22px;
    `}

  ${({ disabled }: StyleProps) =>
    disabled &&
    css`
      background-color: var(--dh-white2);
    `}
`;
