import { EClusterDescriptionKey, EClusterNameKey } from "model/enums";
import { IProduct } from "model/interfaces";
import { useTranslation } from "next-i18next";

interface ICluster {
  name?: string;
  description?: string;
}

const useCluster = (product: IProduct): ICluster => {
  let name = product.clusterAlias;
  let description = product.description;
  const { t } = useTranslation('main');

  switch (product.name) {
    case EClusterNameKey.ctdParttime:
      name = t('cluster.ctdParttimeName');
      break;
    case EClusterNameKey.ctdFulltime:
      name = t('cluster.ctdFulltimeName');
      break;
    case EClusterNameKey.ctdBackend:
      name = t('cluster.ctdBackendName');
      break;
    case EClusterNameKey.ctdFrontend:
      name = t('cluster.ctdFrontendName');
      break;
    case EClusterNameKey.fullstack:
      name = t('cluster.fullstackName');
      break;
    case EClusterNameKey.dataScience:
      name = t('cluster.dataScienceName');
      break;
    case EClusterNameKey.dataAnalytics:
      name = t('cluster.dataAnalyticsName');
      break;
    case EClusterNameKey.onDemand:
      name = t('cluster.onDemandName');
      break;
  }

  switch (product.description) {
    case EClusterDescriptionKey.ctdDefault:
      description = t('cluster.ctdDefaultDescription');
      break;
    case EClusterDescriptionKey.ctdBackend:
      description = t('cluster.ctdBackendDescription');
      break;
    case EClusterDescriptionKey.ctdFrontend:
      description = t('cluster.ctdFrontendDescription');
      break;
    case EClusterDescriptionKey.fullstackDefault:
      description = t('cluster.fullstackDefaultDescription');
      break;
    case EClusterDescriptionKey.dataScienceDefault:
      description = t('cluster.dataScienceDefaultDescription');
      break;
    case EClusterDescriptionKey.dataAnalyticsDefault:
      description = t('cluster.dataAnalyticsDefaultDescription');
      break;
    case EClusterDescriptionKey.settleDebtsDefault:
      description = t('cluster.settleDebtsDefaultDescription');
      break;
    case EClusterDescriptionKey.settleDebtsDefault:
      description = t('cluster.settleDebtsDefaultDescription');
      break;
    case EClusterDescriptionKey.onDemandDefault:
      description = t('cluster.onDemandDefaultDescription');
      break;
    case EClusterDescriptionKey.onDemandFront:
      description = t('cluster.onDemandFrontDescription');
      break;
    case EClusterDescriptionKey.onDemandBack:
      description = t('cluster.onDemandBackDescription');
      break;
  }

  return { name, description };
};

export default useCluster;