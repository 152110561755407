import React, { FunctionComponent } from 'react';
import Header from 'components/Header/Header';
import {
  Container,
  Main,
  LayoutContainer
} from 'components/Layout/MainAsideLayout.styled';

const MainAsideLayout: FunctionComponent = ({ children }) => {
  return (
    <Container>
      <Main>
        <LayoutContainer>
          <Header showBreadcrumbs={true} />
          {children}
        </LayoutContainer>
      </Main>
    </Container>
  );
};

export default MainAsideLayout;
