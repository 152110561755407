import { Locale } from 'date-fns';
import { es, ptBR } from 'date-fns/locale';
import { ECountries } from 'model/enums';
import { useState, useEffect, useMemo } from 'react';

const useDateLocale = (country = ''): Locale => {
  const [dateLocale, setDateLocale] = useState<Locale>(es);

  const initialDateLocale = useMemo(() => {
    const currentDateLocale = country === ECountries.BR ? ptBR : es;

    return currentDateLocale;
  }, [country]);

  useEffect(() => {
    if (country) setDateLocale(initialDateLocale);
  }, [country]);

  return dateLocale;
};

export default useDateLocale;