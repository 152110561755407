import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Main = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  /* Mobile */
  @media screen and (max-width: 1199px) {
    margin-top: var(--asideFixedMobileHeigth);
  }

  /* Desktop */
  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    margin-right: var(--asideFixedWidth);
  }

  /* Wide Desktop */
  @media screen and (min-width: 1501px) {
    margin-right: var(--asideRelativeWidth);
  }
`;

export const LayoutContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 24px;

  /* Mobile */
  @media screen and (min-width: 1200px) {
    padding: 60px;
  }
`;
